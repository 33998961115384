import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Landing in Tech is a podcast where I speak with developers about their journey into tech, their lessons, successes, failures and anything in between. A new episode is released twice a month and the talk is done live on twitch on my twitch channel - `}<a parentName="p" {...{
        "href": "https://twitch.tv/theflyingdev"
      }}>{`The Flying Dev`}</a>{`.`}</p>
    <p>{`I've always been a curious person and I find it fascinating how many different paths folks took to become developers. My objective for the podcast is to help other people to get their foot into tech by showing how each guest became a developer. `}</p>
    <p>{`Together with the live streams and podcast, I've started creating written content focused on soft skills and tech-related topics. I've also started a `}<a parentName="p" {...{
        "href": "https://discord.com/invite/ZUk4eRh"
      }}>{`community in discord`}</a>{`.`}</p>
    <h2 {...{
      "id": "the-live-streams",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-live-streams",
        "aria-label": "the live streams permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Live Streams`}</h2>
    <p>{`Like I've mentioned before, the live streams are done on my twitch channel. I am using the Twitch connector for `}<a parentName="p" {...{
        "href": "https://fabiorosado.dev/projects/opsdroid/"
      }}>{`opsdroid`}</a>{` and a custom made skill to allow viewers to ask questions. `}</p>
    <p>{`To show the viewers questions, I am using KeystoneJS to create a dashboard that allows me to select a question to show on the stream. To do that I am using a simple Nuxt app and importing it as a browser source on OBS.`}</p>
    <h2 {...{
      "id": "the-podcast",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-podcast",
        "aria-label": "the podcast permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Podcast`}</h2>
    <p>{`I'm both live streaming and recording the talks, this is because OBS allows me to save two audio tracks, which makes it easier to edit the audio once the live talk is finished.`}</p>
    <p>{`To edit the audio, I am using Adobe Audition.
To serve the audio, I am using soundcloud.
To get the transcripts, I am using Otter.ai`}</p>
    <h2 {...{
      "id": "the-website",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-website",
        "aria-label": "the website permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Website`}</h2>
    <p>{`The whole website was designed and coded from scratch. I am using GatsbyJS, MDX, MDXembed and custom made components for the posts and episode page.`}</p>
    <p>{`I am also using TailwindCSS on the website, since Tailwind allows me to create components and layouts pretty quick. But I also have a few custom CSS rules created by hand.`}</p>
    <h2 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Website:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://landingintech.com/"
        }}>{`https://landingintech.com/`}</a></li>
      <li parentName="ul"><strong parentName="li">{`SoundCloud:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://soundcloud.com/landingintech"
        }}>{`https://soundcloud.com/landingintech`}</a></li>
      <li parentName="ul"><strong parentName="li">{`GitHub repo:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://github.com/LandingInTech/landingintech"
        }}>{`https://github.com/LandingInTech/landingintech`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      